<template>
  <router-view></router-view>
</template>

<script setup>

</script>

<style>
#app {
  width: 100%;
  height: 100%;
  display: flex;
  font-family: var(--font-family);
  box-sizing: border-box;
}

:root {
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
}

@media only screen and (max-width: 450px) {
  html,
  body {
    font-size: 14px;
  }
}

::-webkit-scrollbar {
  width: 0;
}
</style>
