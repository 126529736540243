// 导入vue-router库中的创建路由
import { createRouter, createWebHashHistory } from 'vue-router'

// 创建路由信息表
const routes = [
    {
        path: '/cartoonizeyourself',
        name: 'CartoonizeYourself',
        component: () => import('@/views/CartoonizeYourself.vue'),
        meta: {
            title: '卡通头像生成',
            isHeadNavigation: true,
            keepAlive: true
        }
    },
    {
        path: '/',
        name: 'MatchProphet',
        component: () => import('@/views/MatchProphet.vue'),
        meta: {
            title: '赛事预言家',
            isHeadNavigation: true,
            keepAlive: true
        }
    }
];

// 创建路由实例
const router = createRouter({
    history: createWebHashHistory(), // 使用createRouter时，必须使用createWebHashHistory
    routes
})

// TODO 全局前置守卫
router.beforeEach(async (to) => {
    // TODO 设置浏览器Title
    document.title = (to.meta.title ? to.meta.title : '') + ' - True Science AI'
})

// 导出实例
export default router